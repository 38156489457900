import type { ComponentProps } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background?.default};
  padding: 2rem;
  border-radius: ${({ theme }) => theme.radii.default};
  max-width: 500px;
  width: 90%;
`;

const ErrorTitle = styled.h2`
  color: ${({ theme }) => theme.colors.error?.default};
  margin-top: 0;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.text?.default};
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.title?.default};
  margin-top: 0;
`;

const Message = styled.p`
  color: ${({ theme }) => theme.colors.text?.default};
`;

const CloseButton = styled.button`
  background-color: ${({ theme }) => theme.colors.background?.inverse};
  color: ${({ theme }) => theme.colors.text?.inverse};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: ${({ theme }) => theme.radii.default};
  cursor: pointer;
  float: right;
`;

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  errorMessage: string;
}

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, onClose, errorMessage }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ErrorTitle>Error</ErrorTitle>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Title>Success</Title>
        <Message>{message}</Message>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};
